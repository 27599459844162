
import { Options, Vue } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import FilterModel from "@/views/page/components/filter-model";
import UsersWithTheirEndpointsRequest from "@/dataSource/api/analytic-users/i-service/models/request/UsersWithTheirEndpointsRequest";
import UsersWithTheirEndpointsResponseModel from "@/dataSource/api/analytic-users/i-service/models/response/UsersWithTheirEndpointsResponseModel";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import UsersWithTheirEndpointsResponse from "@/dataSource/api/analytic-users/i-service/models/response/UsersWithTheirEndpointsResponse";
import getUTC from "@/function/getUTC";

@Options({
  name: "UserList",
  components: {
    Page,
  },
})
export default class UserList extends Vue {
  filter: FilterModel = new FilterModel();
  usersWithTheirEndpointsRequest = new UsersWithTheirEndpointsRequest();
  usersWithTheirEndpointsResponse: Array<UsersWithTheirEndpointsResponseModel> = new Array<UsersWithTheirEndpointsResponseModel>();
  pageResponse: PageResponse = new PageResponse();
  skipPage: number = 0;
  takePage: number = 20;
  sortType: number = 1;
  isLoading: boolean = false;

  error = {
    isSuccess: true,
    errorMessage: "",
  };

  filterData() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.getData();
  }

  async getData() {
    this.isLoading = true;
    this.usersWithTheirEndpointsRequest = {
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: { Skip: this.skipPage, Take: this.takePage },
      SortingModel: null, //todo чето тут надо, этой строчки не было была ошибка
    };
    let res: UsersWithTheirEndpointsResponse;
    let tmpFilter = getUTC(this.usersWithTheirEndpointsRequest);
    try {
      res = await this.$api.AnalyticUsersService.getUsersWithTheirEndpointsAsync(
        tmpFilter
      );
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.error.isSuccess = false;
      this.error.errorMessage = error;
      this.isLoading = false;
    }
    this.usersWithTheirEndpointsResponse = res.Endpoints;
    this.pageResponse = res.Page;
    this.isLoading = false;
  }

  changePage(page) {
    this.skipPage = (page - 1) * this.takePage;
    this.getData();
  }
}
